import React from "react"
import Typist from "react-typist"

//styles
import AboutPageStyles from "./AboutPage.module.scss"

export default function ContactPageMobile({ success }) {
  return (
    <div className={AboutPageStyles.mobileSvg}>
      <svg
        width="80%"
        height="100%"
        fill="none"
        viewBox="0 0 395 313"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="14.107"
          y="247.681"
          width="366.786"
          height="65.11"
          rx="11"
          fill="url(#paint0_linear)"
        />
        <rect y="239" width="395" height="21.703" rx="10.852" fill="#EACDA3" />
        <g className={AboutPageStyles.sparkles} fill="#EFE9E2">
          <path d="M38 17.5C23.6 17.5 28.2 0 28.2 0h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4S23.5 17.5 38 17.5zM381 176.5c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5z" />
        </g>
        <g opacity=".3">
          <path
            d="M356.3 177c-2.4 0-4.4-2-4.4-4.4V164h-19.2v8.6c0 2.4-2 4.4-4.4 4.4-2.3 0-4.2 1.9-4.2 4.2v50.7c0 4.3 3.5 7.8 7.8 7.8h20.8c4.3 0 7.8-3.5 7.8-7.8v-50.7c-.1-2.4-1.9-4.2-4.2-4.2z"
            fill="url(#paint1_linear)"
          />
          <path
            opacity=".1"
            d="M356.3 177c-2.4 0-4.4-2-4.4-4.4V164h-7.2v8.6c0 2.4 2 4.4 4.4 4.4 2.3 0 4.2 1.9 4.2 4.2v50.7c0 4.3-3.5 7.8-7.8 7.8h7.2c4.3 0 7.8-3.5 7.8-7.8v-50.7c-.1-2.4-1.9-4.2-4.2-4.2z"
            fill="#D8D8D8"
          />
          <path
            d="M343.5 160.3H341c-1.5 0-2.8-1.3-2.8-2.8v-15.4c0-2.3 1.8-4.1 4.1-4.1 2.2 0 4.1 1.8 4.1 4.1v15.4c-.1 1.5-1.3 2.8-2.9 2.8z"
            fill="#3864AD"
          />
          <path
            opacity=".1"
            d="M342.8 138c-.4 0-.8.1-1.2.2 1.7.7 2.9 2.7 2.9 5.1v14.3c0 1.4-.9 2.6-2 2.6h2.4c1.1 0 2-1.2 2-2.6v-14.3c0-2.9-1.8-5.3-4.1-5.3z"
            fill="#D8D8D8"
          />
          <path d="M350.6 156h-16.8v2.7h16.8V156z" fill="#3C3C3B" />
          <path
            opacity=".17"
            d="M342.3 230.2c-1.4 0-2.6-5.1-2.6-6.8V165c0-1.7 1.2-3.1 2.6-3.1 1.4 0 2.6 1.4 2.6 3.1v58.4c0 1.7-1.2 6.8-2.6 6.8z"
            fill="#C95F18"
          />
          <path
            d="M352.3 164.7h-20.1c-1.8 0-3.3-1.5-3.3-3.3 0-1.8 1.5-3.3 3.3-3.3h20.1c1.8 0 3.3 1.5 3.3 3.3 0 1.8-1.5 3.3-3.3 3.3z"
            fill="#3864AD"
          />
          <path
            d="M324 217.8h17.2c3.3 0 6-2.7 6-6v-13.5c0-3.3-2.7-6-6-6H324v25.5z"
            fill="#3C3C3B"
          />
          <path
            d="M324.4 216.5h19.2c1 0 1.8-.7 1.8-1.5v-19.8c0-.8-.8-1.5-1.8-1.5H324"
            stroke="#fff"
            strokeWidth=".142"
            strokeMiterlimit="10"
          />
        </g>
        <g>
          <path
            d="M221.746 199.782v-5.188h-25.492v5.188c0 6.529-5.277 11.896-11.896 11.896a20.804 20.804 0 00-20.841 20.841v6.798h90.966v-6.798a20.804 20.804 0 00-20.841-20.841c-6.529 0-11.896-5.367-11.896-11.896z"
            fill="#F4F3F4"
          />
          <path
            d="M330.512 194.594H87.488c-1.878 0-3.488-1.521-3.488-3.488V45.488C84 43.61 85.52 42 87.488 42h243.024c1.878 0 3.488 1.52 3.488 3.488v145.707c0 1.878-1.61 3.399-3.488 3.399z"
            fill="#808181"
          />
          <g fill="#F4F3F4">
            <path d="M209.045 184.755a4.92 4.92 0 100-9.84 4.92 4.92 0 000 9.84zM324.519 164.988H93.481c-1.878 0-3.488-1.521-3.488-3.489V50.497c0-1.878 1.52-3.488 3.488-3.488H324.52c1.878 0 3.488 1.52 3.488 3.488V161.5c-.089 1.968-1.61 3.489-3.488 3.489z" />
          </g>
          <path
            d="M261.37 194.594c-.536 49.821 53.757-11.449 54.294 32.916"
            stroke="#4E4D4D"
            strokeWidth="1.654"
            strokeMiterlimit="10"
          />
          <path
            d="M325.413 239.764H301.8c-1.163 0-2.058-.984-2.058-2.057v-4.652a5.993 5.993 0 015.993-5.992h15.832a5.993 5.993 0 015.993 5.992v4.652c-.089 1.162-.984 2.057-2.147 2.057z"
            fill="#1B1B1B"
          />
        </g>
        <g>
          <path
            d="M72.9 151.8c-3.4 0-6.2-2.8-6.2-6.2v-12H40.1v12c0 3.4-2.8 6.2-6.2 6.2-3.2 0-5.8 2.6-5.8 5.8v70.6c0 6 4.9 10.8 10.8 10.8h28.9c6 0 10.8-4.9 10.8-10.8v-70.6c.1-3.2-2.5-5.8-5.7-5.8z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M68.2 133.7H38.6c-1.1 0-2-.9-2-2V129c0-1.1.9-2 2-2h29.6c1.1 0 2 .9 2 2v2.7c0 1.1-.9 2-2 2z"
            fill="#3A65AE"
          />
          <path
            opacity=".1"
            d="M72.9 151.8c-3.4 0-6.2-2.8-6.2-6.2v-12h-10v12c0 3.4 2.8 6.2 6.2 6.2 3.2 0 5.8 2.6 5.8 5.8v70.6c0 6-4.9 10.8-10.8 10.8h10c6 0 10.8-4.9 10.8-10.8v-70.6c0-3.2-2.6-5.8-5.8-5.8z"
            fill="#D8D8D8"
          />
          <path
            d="M28.1 210.8h26.3c3.3 0 6-2.7 6-6v-23.5c0-3.3-2.7-6-6-6H28.1v35.5z"
            fill="#3D3D3B"
          />
          <path
            d="M28.6 209h26.7c1.4 0 2.5-1 2.5-2.1v-27.6c0-1.2-1.1-2.1-2.5-2.1H28"
            stroke="#fff"
            strokeWidth=".198"
            strokeMiterlimit="10"
          />
          <path
            opacity=".1"
            d="M67.4 127h-4.8c1.5 0 2.8 1.2 2.8 2.8v1.1c0 1.5-1.2 2.8-2.8 2.8h4.8c1.5 0 2.8-1.2 2.8-2.8v-1.1c0-1.5-1.3-2.8-2.8-2.8z"
            fill="#D8D8D8"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="197.5"
            y1="247.681"
            x2="197.5"
            y2="261.872"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9A5C39" />
            <stop offset="1" stopColor="#AD775B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="336.698"
            y1="214.579"
            x2="356.804"
            y2="182.907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#552911" />
            <stop offset="1" stopColor="#C25D17" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="45.568"
            y1="204.112"
            x2="73.596"
            y2="160.05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#542A14" />
            <stop offset="1" stopColor="#C35E19" />
          </linearGradient>
        </defs>

        <foreignObject x="100" y="50" width="210" height="110">
          <div
            className={AboutPageStyles.computer}
            xmlns="http://www.w3.org/1999.xhtml"
          >
            <div width="50">
              <div>
                <Typist startDelay={500}>
                  <span>{"Currently Learning:"}</span>
                  <br />
                  <span>{"Shopify"}</span>
                  <Typist.Backspace count={7} delay={200} />
                  <span>{"and"}</span>
                  <Typist.Backspace count={3} delay={200} />
                  <span>{"Gatsby"}</span>
                </Typist>
              </div>
            </div>
          </div>
        </foreignObject>
      </svg>
    </div>
  )
}

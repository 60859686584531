import React from "react"

import AboutPage from "../components/About/AboutPage"
import Seo from "../components/Seo/Seo"

export default function AboutMe({ location }) {
  return (
    <>
      <Seo title="ChemToCode | About" pathname={location.pathname} />
      <AboutPage />
    </>
  )
}

import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

//styles
import AboutPageStyles from "./AboutPage.module.scss"

//components
import AboutDesktop from "./AboutDesktop"
import AboutMobile from "./AboutMobile"

export default function Aboutpage() {
  const breakpoints = useBreakpoint()
  return (
    <>
      <div className={AboutPageStyles.container}>
        <div className={AboutPageStyles.borderHighlight}>
          <div className={AboutPageStyles.highlights}>
            <p>Highlights</p>
            <ul>
              <li>
                Graduated from{" "}
                <span style={{ color: "#2e8d65", fontWeight: "bolder" }}>
                  Northcoders{" "}
                </span>
                coding bootcamp
              </li>
              <li>
                Part of the first 100 TechUpWomen led by{" "}
                <span style={{ color: "#2e8d65", fontWeight: "bolder" }}>
                  Prof. Sue Black
                </span>{" "}
                with the Insitute of Coding
              </li>
              <li>
                <span style={{ color: "#2e8d65", fontWeight: "bolder" }}>
                  MChem{" "}
                </span>
                in chemistry with medicinal chemistry
              </li>
            </ul>
            <i className={`ri-award-fill ${AboutPageStyles.award}`}></i>
          </div>
        </div>
        <div className={AboutPageStyles.borderTech}>
          <div className={AboutPageStyles.techStack}>
            <p>
              Tech <i className={`ri-stack-line ${AboutPageStyles.stack}`}></i>{" "}
              Stack
            </p>
            <ul>
              <li>HTML , CSS</li>
              <li>
                <span style={{ color: "#2e8d65", fontWeight: "bolder" }}>
                  Languages:
                </span>{" "}
                JavaScript, Python
              </li>
              <li>
                <span style={{ color: "#2e8d65", fontWeight: "bolder" }}>
                  Frameworks:
                </span>{" "}
                React, Gatsby
              </li>
              <li>
                <span style={{ color: "#2e8d65", fontWeight: "bolder" }}>
                  Backend:
                </span>{" "}
                Express, Node.js, PostreSQL, Firebase, GraphQL
              </li>
              <li>
                <span style={{ color: "#2e8d65", fontWeight: "bolder" }}>
                  Testing:
                </span>{" "}
                Chai, Mocha, Jest
              </li>
              <li>
                <span style={{ color: "#2e8d65", fontWeight: "bolder" }}>
                  Design:
                </span>{" "}
                Adobe Illustrator, Figma
              </li>
            </ul>
          </div>
        </div>
      </div>
      {breakpoints.md ? <AboutMobile /> : <AboutDesktop />}
    </>
  )
}
